<template>
  <auth-layout :layoutClass="'sighup-page'">
    <router-view />
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "SignUpPage",
  components: { AuthLayout },
};
</script>

<style lang="scss"></style>
